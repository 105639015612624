import { BBSOL_MINT, BSOL_MINT, HSOL_MINT, JITOSOL_MINT, JUPSOL_MINT, MSOL_MINT, VSOL_MINT } from './mints';

// TODO: webworker change, undo if reverted
export const ENV = localStorage ? localStorage.getItem('web3.env') || 'mainnet-beta' : 'mainnet-beta';

export const SOL_MINTS = ['So11111111111111111111111111111111111111111', 'So11111111111111111111111111111111111111112']; // TODO: Remove So11111111111111111111111111111111111111111 it does not exist on mainnet, some crazy issue pops up sometimes
export const STABLECOIN_SYMBOLS = ['USDH', 'USDC', 'USDT', 'UXD'];
export const STABLECOIN_MINTS = ['EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'];
export const MULTIPLY_MINTS = [MSOL_MINT, BSOL_MINT, JITOSOL_MINT, JUPSOL_MINT, HSOL_MINT, VSOL_MINT, BBSOL_MINT];

export const CollateralTokens =
  ENV === 'mainnet-beta'
    ? ([
        'USDC',
        'USDH',
        'SOL',
        'ETH',
        'BTC',
        'mSOL',
        'stSOL',
        'USDT',
        'ORCA',
        'MNDE',
        'HBB',
        'USH',
        'DAI',
        'UXD',
        'SCNSOL',
        'DUST',
        'USDR',
        // 'kUSDHUSDCOrca',
        // 'kSOLSTSOLOrca',
        'JSOL',
        'BONK',
        'JitoSOL',
        'SAMO',
        'LAINESOL',
        'bSOL',
        'HADES',
        'RAY',
        'RLB',
        'cgntSOL',
        'wBTC',
        'HXRO',
        'HNT',
        'vPts',
        'PYUSD',
      ] as const)
    : ([
        'USDC',
        'USDH',
        'SOL',
        'ETH',
        'BTC',
        'mSOL',
        'stSOL',
        'USDT',
        'USH',
        'DAI',
        'wBTC',
        'JUP',
        'bbSOL',
        'ezSOL',
        'kySOL',
        'REZ',
        'ORE',
        'GNON',
        'GRASS',
        'SLERF',
        'MUMU',
        'LOCKIN',
        'MOODENG',
        'FWOG',
        'GOAT',
        'SCF',
        'DVY',
        // 'kUSDHUSDCOrca',
        // 'kSOLSTSOLOrca',
      ] as const);
export const RewardTokens = ['ORCA', 'MNDE', 'HBB', 'JSOL', 'LDO', 'HDG', 'RATIO'] as const;
export const KaminoRewardTokens = ['HBB', 'USDH', 'LDO', 'UXP'] as const;
export const KTokens = ['kUSDHUSDCOrca', 'kSOLSTSOLOrca'] as const;
export const StablesTokens: StablesTokensTypes[] = ['USDC', 'USDT', 'USDR', 'USDH', 'DAI', 'UXD', 'PYUSD'];
export type StablesTokensTypes = 'USDC' | 'USDT' | 'USDR' | 'USDH' | 'DAI' | 'UXD' | 'PYUSD';
// do not show rewards on portfolio page for strategies with these tokens
export const HiddenRewardsTokens = ['SAMO'];

export const AllTokens = [...CollateralTokens, ...RewardTokens, ...KaminoRewardTokens] as const;

export const CUSTOM_SYMBOLS: Record<string, string> = {
  bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1: 'bSOL',
  mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So: 'mSOL',
  '7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj': 'stSOL',
  J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn: 'JitoSOL',
  '8Ak9JgLeTo6ubG5vfpuAR59ANpGjTB8HFDwYjpZbkPeB': 'kJitoSOL-USDC',
  Dk2X1HCbwJae44P7FpqdFoeT6LEw4JVyyHvZMHUzHWbi: 'kJitoSOL-SOL',
  GYiUmJ8reqYAdTQtx6CRFawHqPXx9yzkUFvaUVE8PskP: 'kJitoSOL-SOL',
  '3Fb5DMRWoBLWD36Lp4BtG41LaFjVeEJNCH9YLNPYdVqj': 'kmSOL-SOL',
  '9HB4kAMLSYfGFfN142DKMyPyHyZQ8pXF8M1STbDudodY': 'kbSOL-SOL',
  '27G8MtK7VtTcCHkpASjSDdkWWYfoqT6ggEuKidVJidD4': 'JLP',
  F23fFqpRNsmWjuUrFpfM1pvoVvMSpLuN6hY978Y1JXLt: 'USC',
  // H2nASb2iZT4D2Mx8NjYvugc67rcR9M9DRNSoyoGLTSf6: 'USDH', // DEVNET
  // HFFc3roPU9XZP3bhFDn47L5BGLfYR3teAW9QKebAo5x: 'BTC',
  // BBmtFEgEAtUwH3Xhd92jP6LeAjDutYWpp8oWLG4NcNDV: 'ETH',
  // DpifwNhipvWLCQat9VExhMe1FKL7iw9cPhbY86uozrVm: 'USDC',
};

export type CollateralToken = typeof CollateralTokens[number];
export type RewardToken = typeof RewardTokens[number];
export type Token = typeof AllTokens[number];
