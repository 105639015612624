import { sortBy } from 'lodash';

import { MarketsFilters } from '../../types/lending';
import { IconName } from '../../uiKitV2/Icon';
import { featureFlags } from '../featureFlags';
import { MAIN_MARKET_ADDRESS } from '../../constants/borrowLend/markets';

export type MarketMetadata = {
  marketAddress: string;
  slug: string;
  title: string;
  description?: string;
  icon: IconName;
  order: number;
  marketIdx: number;
  isNew: boolean;
};

export const marketsMetadata: MarketMetadata[] = [
  {
    marketAddress: 'H6rHXmXoCQvq8Ue81MqNh7ow5ysPa1dSozwW3PU1dDH6',
    slug: MarketsFilters.jito,
    icon: 'Jto',
    title: 'Jito',
    order: 1,
    marketIdx: 4,
    isNew: true,
  },
  {
    marketAddress: 'DxXdAyU3kCjnyggvHmY5nAwg5cRbbmdyX3npfDMjjMek',
    slug: MarketsFilters.jlp,
    icon: 'Jlp',
    title: 'JLP',
    order: 2,
    marketIdx: 1,
    isNew: false,
  },
  {
    marketAddress: MAIN_MARKET_ADDRESS,
    slug: MarketsFilters.main,
    icon: 'MoreGrid',
    title: 'Main',
    order: 3,
    marketIdx: 0,
    isNew: false,
  },
  {
    marketAddress: 'ByYiZxp8QrdN9qbdtaAiePN8AAr3qvTPppNJDpf5DVJ5',
    slug: MarketsFilters.altcoins,
    icon: 'Altcoins',
    title: 'Altcoins',
    order: 5,
    marketIdx: 3,
    isNew: false,
  },
  ...(featureFlags.isEthenaMarketEnabled
    ? ([
        {
          marketAddress: 'BJnbcRHqvppTyGesLzWASGKnmnF1wq9jZu6ExrjT7wvF',
          slug: MarketsFilters.ethena,
          icon: 'Ethena',
          title: 'Ethena',
          order: 4,
          marketIdx: 2,
          isNew: false,
        },
      ] as MarketMetadata[])
    : []),
];

export const DEFAULT_FILTER = marketsMetadata[0].slug;

export const marketMetadataByOrder = sortBy(marketsMetadata, 'order');

export const getMarketMetadata = (address: string) => marketsMetadata.find((item) => item.marketAddress === address);
